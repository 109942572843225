.book-shelf-model{
  .modal-dialog{
    max-width: 900px;
  }
  .book-card-container{
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .book-title{
      font-size: 1em;
      margin: 0;
      text-align: center;
    }
  }
}