@import "../variables.scss";

.left_side_bar {
  display: inline-table;
  height: fit-content;
  padding-top: 0;
  width: 15em;
  background-color: #ffffff;
  margin-right: 1em;
  border-radius: 0.25em;
  min-height: $min-column-height;
  padding-bottom: 1.5em;

  h2 {
    color: $headerFontColor;
    font-size: 1em;
    text-align: left;
    margin-left: 0.5em;
  }

  img {
    margin: 0.5em;
  }

  .blog_post{
    p{
      font-size: 1em
    }

    p[data-f-id="pbf"]{
      display: none
    }
  }
}
@media only screen and (max-width: 1040px) {
  .left_side_bar {
    width: 95%;
    max-width: 96%;
    margin: auto;
    margin-top: 2em;
    display: block;
    padding: 1em 0;
    min-height: auto;
  }
}

@media only screen and (max-width: 700px) {
  .left_side_bar {
    margin: auto;
    margin-top: 2em;
    padding: 1em 0;
    width: 90%;
    padding-bottom: 0.5em;
    display: block;
    min-height: auto;
  }
  img {
    max-height: 20em;
  }
}
