.shopping-page {
  .shopping-page-container {
    padding: 0.5em 0;
  }
}

.item-row {
  margin: 1em 0;
  display: flex;
  padding: 0em 0.5em;

  .preview-image-container {
    margin: 0 0.5em;
    width: 40%;
    height: 12em;
    background-size: cover;
    background-position: center;
    border: 1px solid #3c3c3c;
    border-radius: 0.7em;
  }

  .item-content-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-left: 0.5em;
    padding-top: 0.5em;

    .quantity-selection-container {
      font-size: 0.9em;
      margin-top: 0.5em;
      .quantity-selection {
        margin-left: 0.5em;
        background-color: white;
        option {
          display: inline-block;
        }
      }
    }

    .item-title {
      font-size: 1.1em;
      line-height: 1.4em;
      padding-bottom: 0.5em;
    }

    .item-shipping-message {
      font-size: 0.75em;
    }

    .item-buy-button {
      background-color: white;
      margin: 0.5em 0;
      width: 4em;
      border: 1px solid;
      border-radius: 0.5em;
      height: 1.8em;
      cursor: pointer;

      &.french {
        width: 6em;
      }
    }
  }
}
@media only screen and (min-device-width: 768px) {
  .shopping-page {
    .shopping-page-container {
      padding: 1em 1em;
      display: flex;
    }
  }

  .item-row {
    flex-direction: column;
    width: 33.3%;
    flex-wrap: wrap;

    .preview-image-container {
      margin: 0 0.5em;
      width: 90%;
      height: 15em;
      background-size: cover;
      background-position: center;
      border: 1px solid #3c3c3c;
      border-radius: 0.7em;
    }

    .item-content-container {
      width: 90%;
      display: flex;
      flex-direction: column;
      padding-left: 0.5em;
      padding-top: 0.5em;
    }
  }
}

@media only screen and (min-device-width: 1040px) {
  .item-row {
    .item-content-container {
      .item-title {
        font-size: 1.2em;
      }

      .item-pricing {
        font-size: 1.2em;
      }

      .item-buy-button {
        font-size: 1em;
      }

      .item-shipping-message {
        font-size: 0.9em;
      }
    }
  }
}
