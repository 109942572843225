.e-reader-container {
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .react-transform-component{
    margin: auto;
    cursor: grab;
  }

  .ebook-image-container{
    margin: auto;
    width: 50em;

    @media only screen and (max-width: 968px) {
      width: 100%;
    }
  }

  &.reading-mode{
    position: fixed;
    background: silver;
    height: 100vh;
    width: 100vw;
    top: 0%;
  }

  img{
    &.reading-mode{
      width: auto;
      height: 90vh;
    }

    @media only screen and (max-width: 564px) {
      width: 100% !important;
      height: auto !important;
      max-height: unset;
    }
  }

  @media only screen and (max-width: 564px) {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}