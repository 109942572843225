#shipping-form {
  padding: 0 1em;
  padding-bottom: 1em;

  .header {
    margin-bottom: 1em;
  }

  .information-form-container {
    display: flex;
    flex-direction: column;
    padding: 0 0.3em;

    hr {
      width: 100%;
      margin: 1em 0;
      color: rgb(225, 228, 214);
    }

    .validation-message {
      margin-top: 1em;
      color: red;
      font-weight: bold;
    }
  }
  input {
    border-radius: 0.2rem;
    border-width: 1px;
    border-color: #e2e8f0;
    padding: 0.5rem 0.5rem;
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
    outline: none;
    margin-bottom: 0.5em;

    &::placeholder {
      color: #cdcccc;
    }
  }

  .input-title {
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 0.4em;
    color: rgba(90, 90, 90, 1);
  }

  .continue-button {
    margin-top: 1em;
    height: 2.5em;
    width: 6.5em;
    padding: 0.5em;
    background-color: #489fe7;
    border-radius: 0.4em;
    color: white;
    border: none;
  }
  .input-info-name,
  .information-form-container,
  .box1,
  .box2 {
    display: grid;
    width: 100%;
  }
}

@media only screen and (min-device-width: 768px) {
  #shipping-form {
    .input-info-name {
      display: grid;
      width: 50%;
    }
    .input-info-container {
      display: flex;
    }

    .box1 {
      display: grid;
      width: 65%;
    }

    .box2 {
      display: grid;
      width: 25%;
      margin-left: 40px;
    }
  }
}

@media only screen and (min-device-width: 1040px) {
  #shipping-form {
    padding: 0 2em 1.5em 2em;

    .header {
      font-size: 1.5em;
    }

    .input-title {
      font-size: 1em;
    }

    .continue-button {
      margin-top: 2em;
      font-size: 1em;
      cursor: pointer;
    }
  }
}
