.order-confirmation{
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .message-container{
    width: 80%;
    text-align: center;
  }
}