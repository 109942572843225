.shopping-page {
  hr {
    width: 92%;
  }

  width: 95%;
  margin: auto;
  height: auto;
  background-color: rgba(241, 241, 240, 0.72);
}

@media only screen and (min-width: 1040px) {
  .shopping-page {
    width: 75em;

    .page-header-container-mobile {
      background-position: center !important;
    }

    .preview-image-container {
      cursor: pointer;
    }
  }
}
