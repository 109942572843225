.privacy-page {
  background-color: #dfdddb;
  width: 75em;
  margin: auto;
  color: rgb(65, 65, 65);
  
  
}
.privacy-page-content-paragraph{
  margin-top:2em;
}
.privacy-page-content{
  width: 60em;
  margin:auto;

  li{
    font-size: 1.5em;
  }
}


