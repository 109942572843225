.tools-page {
  background-color: #dfdddb;
  width: 75em;
  margin: auto;
  //border-radius: 0.25em;
}

.tools-page-squares-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.tools-page-square-container {
  margin: 2em;
  width: 30em;
  border-radius: 0.4em;
  text-align: left;
}
.tools-page-square {
  background-color: white;
  border-radius: 0.4em;
  margin: 2em;
}

.tools-page-square-title {
  font-weight: bold;
  color: #464646;
  margin: 0em;
  font-size: 1.2em;
}

.tools-page-square-text {
  margin: 0em;
  color: #464646;
  font-size: 1.2em;
}

.tools-page-square-image {
  width: 30em;
  border-radius: 0.4em;
  border: 1px solid #ccc !important;
}

@media only screen and (max-width: 500px) {
  .tools-page-square-image {
    width: 18em;
  }

  .tools-page-square-container {
    width: 18em;
    margin: 1em;
    
  }
  .tools-page-square {
    margin: 2em;
    margin:auto;
    margin-bottom: 1em;
    margin-top:1em;
  }

  .tools-page-squares-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}


@media (max-width:767px) and (min-width:500px) { 
  .tools-page-square-image {
    width: 24em;
  }

  .tools-page-square-container {
    display: flex;
    justify-content: center;
    width: 24em;
    margin: 2em;
    
  }
  .tools-page-square {
    margin: 2em;
    margin:auto;
    margin-bottom: 1em;
    margin-top:1em;
  }

  .tools-page-squares-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

@media (max-width:800px) and (min-width:768px) { 
  .tools-page-square-image {
    width: 19em;
  }
  .tools-page-square-container {
    width: 19em;
    margin: 1em;
  }
  .tools-page-square {
    margin: 1em;
  }
}

@media (max-width:950px) and (min-width:800px) { 
  .tools-page-square-image {
    width: 22em;
  }
  .tools-page-square-container {
    width: 22em;
    margin: 1em;
  }
  .tools-page-square {
    margin: 1em;
  }
}

@media (max-width:1178px) and (min-width:950px) { 
  .tools-page-square-image {
    width: 25em;
  }

  .tools-page-square-container {
    width: 25em;
    margin: 1.5em;
  }

  .tools-page-square {
    margin: 1em;
  }

}

@media only screen and (min-width: 1179px) { 
  .tools-page-square-image {
    width: 30em;
  }

  .tools-page-square-container {
    width: 30em;
    margin: 2em;
    
  }
  .tools-page-square {
    margin: 2em;
  }
}

.tools-page-mobile {
  background-color: #dfdddb;
  margin-left:0.5em;
  margin-right:0.5em;
}

.tools-page-square-image-mobile{
  width: 40vw;
  border-radius: 0.4em;
  border: 1px solid #ccc !important;
 }
 
 .tools-page-square-mobile{
  background-color: white;
  border-radius: 0.4em;
  padding: 0.5em;
 }


 .tools-page-square-button-container{
   display:flex;
   justify-content: center;
   width: 100%;
 }
 .tools-page-square-button{
  margin-top:0.5em;
  background-color: #86c6c2; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  justify-content: center;
  font-size: 16px;
  border-radius: 25px;
 }
