*:focus {
  outline: none;
}

.modal-title {
  margin-top: 0;
}

#order-summary-container {
  width: 80vw;

  .row {
    width: 100%;
  }

  .bordered-container {
    padding: 1em;
    border: 1px solid #d5d5d5;
    border-radius: 0.3em;
    margin-bottom: 1.1em;
  }

  .title-text {
    font-size: 1em;
    font-weight: bold;
  }

  .order-item-row {
    font-size: 0.8em;
    margin: 0.2em 0;
    padding: 0 0.2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .order-summary {
    hr {
      width: 100%;
      margin: auto !important;
    }

    .order-total {
      font-weight: bold;
    }

    .payment-message {
      text-align: center;
      font-size: 0.9em;
      width: 80%;
      margin: 1em auto;
      color: red;
      font-weight: bold;
    }

    .order-confirmation-button {
      width: 75%;
      background-color: #6bc566;
      font-weight: bold;
      color: white;
      padding: 1em;
      margin: auto;
      border-radius: 0.7em;
      font-size: 1em;
      cursor: pointer;
    }
  }

  .shipping-information {
    .title-text {
      margin-bottom: 0.5em;
    }
    .phone-row {
      margin-top: 0.7em;
    }

    .change-button {
      font-size: 0.6em;
      color: cornflowerblue;
      margin-left: 1em;
    }
  }

  .order-items-container {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  #order-summary-container {
    .order-items-container {
      display: flex;
      flex-direction: column;

      .item-row {
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;

        .preview-image-container {
          width: 40%;
        }

        .item-content-container {
          width: 50%;
          padding-left: 1.3em;
        }
      }
    }
  }
}

@media only screen and (min-width: 1040px) {
  #order-summary-container {
    * {
      font-size: 1em;
    }

    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;

    .order-summary {
      width: 35%;

      .row {
        margin: 0.6em 0;
      }

      .payment-message {
        margin: 1.5em auto;
      }
    }

    .desktop-column-wrapper {
      width: 57%;
    }

    .order-items-container {
      .item-row {
        flex-direction: row;
        width: 100%;
        flex-wrap: nowrap;

        .preview-image-container {
          width: 35%;
        }

        .item-content-container {
          width: 55%;
        }
      }
    }

    .shipping-information {
      .change-button {
        font-size: 0.6em;
        cursor: pointer;
      }
    }
  }
}
