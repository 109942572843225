.announcement-container{
  height: fit-content;
  flex-grow: 1;
  width: 90%;
  background-color: white;
  border-radius: 0.25em;
  margin: auto;
  margin-bottom: 1em;

  .announcement-title{
    font-size: 1.25em;
    font-weight: bold;
    margin: 0.5em 0;
  }

  .announcement-description{
    padding: 0 1em;
    padding-bottom: 1em;
  }

  .youtube-player{
    width: 100%;
  }

  .series-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.2em 2em;

    .button{
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 1040px) { 
    width: 100%;
    margin: 0;
  }
}