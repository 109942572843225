.comic-paragraph {
  // width: 37em;
  text-align: Left;
  margin-left: 1em;
  margin-right: 1em;
  color: #464646;
}

.comic-paragraph-title {
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
}

.comic-paragraph-text {
  color: navy;
}

@media only screen and (max-width: 700px) { 
  .comic-paragraph-title {
    font-size: 1em;
  }
}