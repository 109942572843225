@import "variables.scss";

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-container {
  min-height: 75vh;
  background-image: url("https://i.ibb.co/YhJtCTB/boxia-background.jpg");
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;

  &.app-spinner {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}

@media only screen and (min-width: 700px) { 
  .app {
    padding-top: 2em;
    padding-bottom: 2em;
    background-image: url("https://i.ibb.co/YhJtCTB/boxia-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #ffd800;
    // background-color: #E5E3E2;
  }
}

@media only screen and (max-width: 700px) { 
  .app {
    padding-top: 2em;
    padding-bottom: 2em;
    background-image: url("https://i.ibb.co/YhJtCTB/boxia-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #ffd800;
    width: 100vw;
  }
}

//@media only screen (max-width:1178px) and (min-width:700px) {
@media only screen and (max-width:1178px) {
  .app {
    padding-top: 4em;
  }
}​

html {
  scroll-behavior: smooth;
}
