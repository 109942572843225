.page-header-container {
  width: 75em;
  position: relative;
  height: 15em;
  background-color: black;
  margin: 0px;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-header-text {
  position: absolute;
  bottom: 0.05em;
  left: 0.5em;
  color: white;
}

.page-header-container-mobile {
  position: relative;
  height: 13em;
  background-color: black;
  margin: 0px;
  //margin-left:0.5em;
  //margin-right:0.5em;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 576px) { 
  .page-header-container-mobile {
    height: 10em;
  }
}
@media only screen and (min-width: 576px) { 
  .page-header-container-mobile {
    height: 13em;
  }
}



