@import "../variables.scss";

$width_of_content: 75em;

.about_page_content {
  width: $width_of_content;
  margin: auto;
}

.background {
  background-color: rgba(255, 255, 255, 0.6);
  width: $width_of_content;
}

.title_about_us {
  width: $width_of_content;
  height: 15em;
  background-image: url("../../img/about-us-edit.jpg");
  background-size: 100% 100%;
  position: relative;
}

.dark_overlap_div {
  width: inherit;
  height: inherit;
  background-color: rgba(0, 0, 0, 0.3);
  h1 {
    position: absolute;
    bottom: 0.5em;
    left: 0.5em;
    margin: 0;
    color: white;
  }
}

.firstPara {
  padding-top: 4em;
  width: $width_of_content * 0.6;
  margin: auto;
  margin-bottom: 4em;
  h3 {
    margin: 0;
    font-size: 1.8em;
    text-align: center;
    color: rgb(65, 65, 65);
  }
}

.mission {
  width: $width_of_content * 0.9;
  margin: auto;
  h1 {
    margin: 0.5em 0;
    color: rgb(46, 46, 46);
  }
}

.mission_content {
  display: flex;
  h4 {
    width: 50%;
    margin: 0;
    font-size: 1.4em;
    font-weight: 400;
  }
}

.four_words {
  width: 50%;
  margin: auto;
  display: flex;
  text-align: center;
}

.left_words {
  margin: 0 5em;
  h3 {
    font-weight: bolder;
  }
}

.top_word {
  margin-bottom: 4.5em !important;
}

.bottom_word {
  margin-top: 4.5em !important;
}

.vision {
  background-image: url("../../img/vision.jpg");
  background-size: 100% 100%;
  height: 18em;
  width: 95%;
  margin: auto;
  h2 {
    color: white;
    font-size: 1.7em;
    margin: 1em;
    padding-top: 0.5em;
  }
  h1 {
    color: white;
    margin-top: 1.8em;
    padding-top: 0.5em;
    text-align: center;
    font-style: italic;
  }
  margin: 5em auto;
}

.values {
  width: 90%;
  margin: 4em auto;
  h1 {
    color: rgb(69, 69, 69);
    margin: 1em auto;
  }
}

.values_words {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: auto;
}

.values_first_word {
  margin-right: auto;
}

.values_middle_words {
  margin: auto;
}

.values_last_word {
  margin-left: auto;
}

.founders {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 7.5em;
  h1 {
    color: rgb(69, 69, 69);
    margin: 1em auto;
  }
}

.bios {
  display: flex;
  justify-content: center;
}

.space_div_bios {
  width: 5em;
}

.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-container {
  display: flex;
  height: 24em;
  padding-left: 14em;
  .see-more-photo-carousel {
    height: 20em;
    width: 80%;
    margin: auto;
    img {
      width: auto;
      max-height: 18em;
    }
  }
}

@media only screen and (max-width: 1040px) {
  .about_page_content {
    width: 95%;
    margin: auto;
  }
  .title_about_us {
    width: 100%;
  }
  .background {
    width: 100%;
  }
  .mission {
    width: 95%;
    h1 {
      text-align: center;
    }
    h4 {
      width: 95%;
      margin: 2em auto;
      text-align: center;
    }
  }
  .carousel-container {
    padding: 0;
    margin: auto;
  }
  .four_words {
    margin: 2em 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .mission_content {
    display: block;
  }
  .values {
    text-align: center;
  }
  .values_words {
    width: 65%;
    justify-content: space-between;
  }
  .values_first_word {
    margin-bottom: 4em;
  }
  .founders {
    h1 {
      text-align: center;
      margin-bottom: 2em;
    }
  }
}

@media only screen and (max-width: 564px) {
  .carousel-container {
    padding: 0;
    height: fit-content;
    img {
      width: 95%;
      margin: auto;
      display: block;
    }
  }
  .carousel_margin_fix {
    margin-bottom: 1em !important;
  }
  .about_page_content {
    width: 90%;
  }
  .background {
    width: 100%;
  }

  .title_about_us {
    width: 100%;
    height: 6em;
  }
  .dark_overlap_div {
    h1 {
      margin: -0.2em;
    }
  }
  .firstPara {
    width: 100%;
    h3 {
      width: 100%;
      font-size: 1.25em;
      margin: auto;
    }
  }
  .mission {
    width: 100%;
    h1 {
      text-align: center;
    }
  }
  .mission_content {
    display: block;
    h4 {
      width: 95%;
      margin: 0.5em auto;
      text-align: center;
    }
  }
  .four_words {
    width: 95%;
    justify-content: space-between;
    h3 {
      font-size: 0.75em;
      margin: 0 0.1em;
    }
  }
  .left_words {
    margin: 0 !important;
  }
  .vision {
    height: 10em;
    width: 95%;
    h2 {
      font-size: 1.2em;
      margin: 0.5em;
      padding-top: 0.5em;
    }
    h1 {
      margin-top: 0.5em;
      padding-top: 0.5em;
      text-align: center;
      font-style: italic;
      font-size: 1.5em;
    }
    margin: 5em auto;
  }
  .values {
    width: 90%;
    margin: 4em auto;
    h1 {
      text-align: center;
    }
  }
  .values_words {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    h2 {
      font-size: 1em;
      text-align: center;
    }
  }
  .values_first_word {
    width: 50%;
    margin-right: auto;
  }

  .values_middle_words {
    margin: auto;
  }

  .values_last_word {
    width: 75%;
    margin-left: auto;
  }
  .top_word {
    margin-bottom: 1em;
  }
  .founders {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 0.5em;
    h1 {
      text-align: center;
    }
  }
  .bios {
    display: block;
    margin: auto;
  }

  .content {
    p {
      width: unset !important;
    }

    .top_content {
      margin-bottom: 1em;
    }

    * {
      font-size: 1em !important;
    }
  }
}
