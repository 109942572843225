#ebook-shopping-section-container {
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }

  .separator::before {
    margin-right: .25em;
  }

  .separator::after {
    margin-left: .25em;
  }

  .item-buy-button{
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .ebook-containers{
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 565px) {
  #ebook-shopping-section-container {
    .ebook-containers{
      flex-direction: column;
    }
  }
}
