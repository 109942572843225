@import "../variables.scss";

.word-of-container {
  text-align: Left;
  margin-left: 1em;
  margin-right: 1em;
  color: $headerFontColor;
  font-size: 0.8em;

  .person{
    color: brown;
    font-size: 1em;
  }

  .text{
    color: navy;
  }
}

.word-of-container-header {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 0.2em;
  margin-top: 0.8em;
  font-family: "Arial";
}

.word-of-container-text {
  font-size: 0.8em;
  margin-top: 0.2em;
}

@media only screen and (max-width: 1040px) { 
  .words_container {
    display: flex;
    width: 100%;
  }
  .word-of-container {  
    width: 50%;
    text-align: center;
    h2 {
      font-size: 1.25em;
    }
  }
  .word-of-container-text {
    font-size: 1em;
  }
}

@media only screen and (max-width: 700px) { 
  .word-of-container {
    width: 90%;
  }
  .words_container {
    display: block;
    width: 100%;
  }
  .word-of-container-header {
    text-align: left;
    font-size: 1em;
  }
  .word-of-container-text { 
    text-align: left;
    font-size: 1em;
  }
}