p {
  font-size: 1.5em;
}

.nav-bar {
  background-color: #e2e2e2;
  align-items: center;
  height: fit-content;
  display: grid;
  grid-template-columns: 0.5fr 2fr 2fr 2fr 2fr 7fr 1.7fr 1.7fr 1.7fr 1.7fr 0.3fr 1.2fr;
  grid-template-rows: 0.5fr;
}

.nav-bar-div {
  max-height: fit-content;
}

.logo-container > a {
  display: flex;
  justify-content: center;
  align-items: center;

  .nav-bar-logo {
    width: 6em;
  }

  span {
    padding-top: 0.3em;
    font-size: 1.2em;
    color: black;
  }
}

.nav-bar-divider {
  font-size: 1em;
  margin-left: 3.3em;
  margin-right: 1.7em;
  text-align: right;
  display: block;
  margin-block-start: 0.8em;
  margin-block-end: 0.8em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.nav-bar-button {
  color: #666;
  font-size: 1em;
  margin-left: 2.5em;
  margin-right: 2.5em;
  text-align: center;
  display: block;
  margin-block-start: 0.8em;
  margin-block-end: 0.8em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  &.language-selector {
    cursor: pointer;
  }
}

.nav-bar-button:hover {
  color: #000;
  text-shadow: 0px 0px 30px #ffffff;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

//////////////////////////////

.nav-bar-mobile {
  position: fixed;
  width: 100%;
  background-color: #ebebeb;
  height: 3em;
  z-index: 100;
}

.nav-bar-mobile-menu {
  a {
    color: black;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}

#logo-mobile {
  width: 6em;
  margin-top: 0.25em;
  margin-left: 0.9em;
  //padding-top:0.5em;
}

#logo-mobile-menu {
  width: 6em;
  margin: auto;
  padding-bottom: 1em;
}

#logo-text-mobile {
  padding-bottom: 2em;
}

.menu-item {
  text-align: center;
}

.nav-bar-mobile-container {
  display: grid;
  grid-template-columns: 1fr 10fr;
  grid-template-rows: 0.5fr;
}

//////////////////////////////

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 40px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
}

/* General sidebar styles */
.bm-menu {
  background: #ebebeb;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
