.login-panel-container {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form-container{
    min-width: 80%;
    max-width: 80vw;
  }
  
  @media only screen and (max-width: 564px) {
  }
}