
.right-side-events-header {
  font-weight: bold;
  font-size: 0.9em;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
  text-align: Left;
  margin-left: 1em;
  margin-right: 1em;
  color: #464646;
}
.event-slider {
  width: 100%;
  min-width: 14em;

  .event-container {
    margin-top: 1em;
  }
  .right-side-events-image {
    margin: auto;
    width: auto;
    max-width: 100%;
    max-height: 10em;
    border-radius: 0.2em;
  }

  .right-side-events-text {
    font-size: 0.7em;
    margin-top: 0.5em;
    text-align: center;
    color: #464646;
  }

  .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 1040px) {  
  .right-side-events-header {
    text-align: center;
  }
}


@media only screen and (max-width: 700px) {  
  .right-side-board {
    width: 100%;
  }
  .right-side-events-header {
    text-align: center;
  }
  .right-side-events-text { 
    font-size: 1em !important;
  }
}
