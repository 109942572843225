.sponsors-page {
  background-color: #dfdddb;
  width: 75em;
  margin: auto;
  padding-bottom: 4em;
  //border-radius: 0.25em;
}

.sponsors-page-mobile {
  background-color: #dfdddb;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.sponsors-page-packages-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.sponsors-page-package-container-header {
  font-weight: bold;
  font-size: 2em;
  color: #2e2e2e;
  margin: 0.25em;
  margin-top: 1em;
  text-align: center;
}

.sponsors-page-package {
  padding: 1em 0;
  margin: 1em;
  background-color: white;
  border-radius: 0.25em;
  width: 17em;
  height: fit-content;
  justify-content: center;
  text-align: center;
}

.sponsors-page-package-image {
  margin-top: 1em;
  border-radius: 2.5em;
  width: 9em;
  height:9em;
  margin-left: 2em;
  margin-right: 2em;
  object-fit: cover;
}

.sponsors-page-package-header {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}

.sponsors-page-package-info {
  font-size: 0.8em;
  max-width: 15em;
  margin: 1em auto;
}

.sponsors-page-services-container {
  background-color: #cef0d8;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 0.5em;
}

.sponsors-page-services-container-header {
  font-weight: bold;
  font-size: 2em;
  color: #2e2e2e;
  margin: 0.75em 0.25em;
  text-align: center;
  margin-bottom: 0;
}

.sponsors-page-card-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.sponsors-page-card {
  width: 13.6em;
  height: 10em;
  background-color: white;
  margin: 1em 3em;
  border-radius: 2.5em;
}

@media (max-width: 884px) and (min-width: 771px) {
  .sponsors-page-card {
    margin: 1em 2em;
  }
}

@media (max-width: 771px) and (min-width: 565px) {
  .sponsors-page-card {
    margin: 1em 1em;
  }
}

@media (max-width: 565px) and (min-width: 0px) {
  .sponsors-page-card {
    margin: 1em 0.5em;
  }
}

.sponsors-page-card-number {
  margin-top: 0.5em;
  font-size: 5em;
  font-weight: bold;
  margin: 0;
  line-height: 1em;
  text-align: center;
}
.sponsors-page-card-month {
  font-weight: bold;
  font-size: 1em;
  margin: 0;
  text-align: center;
}

.sponsors-page-card-money {
  font-size: 1em;
  margin: 0;
  text-align: center;
}

.sponsors-page-card-text {
  margin-top: 0.7em;
}

#sponsors-page-card-tier-1 {
  color: #7b7b7b;
}

#sponsors-page-card-tier-2 {
  color: #85906d;
}

#sponsors-page-card-tier-3 {
  color: #23b716;
}
.sponsors-page-contact {
  justify-content: center;
}
.sponsors-page-contact-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: white;
  border-radius: 0.5em;
  padding: 0.5em;

  .coming-soon-emailing-text {
    text-align: center;

    & > a {
      color: black;
      text-decoration: underline;
    }
  }

  .contact-us-link-container {
    text-align: center;
    a {
      color: #a5a5a5;
    }
  }
}

@media only screen and (min-width: 1178px) {
  .sponsors-page-contact-container {
    width: 70em;
  }
}

.sponsors-page-services-container-contact-text {
  margin: 0;
  font-size: 0.9em;
}

.sponsors-page-services-container-contact-text:hover {
  text-decoration: underline;
}
.sponsors-page-question {
  h3 {
    margin: 0.5em 0;
    font-size: 1.25em;
    color: rgb(97, 97, 97);
    text-align: left;
  }
  input {
    border: none;
    border-bottom: thin solid rgb(202, 202, 202);
    width: (70em * 0.9) / 3.5;
    font-size: 1em;
    //width: 20em;
    //float: left;
  }
  margin-bottom: 2em;
  //width: 20em;
  margin: auto 5em;
}

@media (max-width: 485px) and (min-width: 0px) {
  
  .submission-container {
    .sponsors-page-contact-submit {
      margin: 2em 0em;
    }
  }
  .submission-message{
    text-align:center;
    width:10em;
    margin:0em;
  }

  .sponsors-page-contact-submit {
    margin:0em;
  }
  .sponsors-page-contact-container-container{
    display:flex;
    justify-content:center;
  }
  
  .sponsors-page-question {
    margin: auto 0em;
  }

  .sponsors-page-dropdown {
    margin: auto 0em;
  }

  .sponsors-page-dropdown select {
    width: 18em;
  }
}

@media only screen and (min-width: 485px) {
  .sponsors-page-question {
    margin: auto 5em;
    input {
      width: 20em;
    }
  }
  .sponsors-page-dropdown {
    margin: auto 5em;
  }

  .sponsors-page-dropdown select {
    width: 20em;
  }
}

.sponsors-page-questions-wrapper {
  display: grid;
  //grid-template-columns: 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
}

@media only screen and (min-width: 992px) {
  .sponsors-page-questions-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 992px) {
  .sponsors-page-questions-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .sponsors-page-contact-container-container {
    display: flex;
    justify-content: center;
  }

  .sponsors-page-contact-submit {
    margin: auto;
    text-align: center;
  }
}

.sponsors-page-questions-margin {
  margin-bottom: 1em;
}

.sponsors-page-question-input-long {
  size: 100;
}

.submission-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1em;
  align-items: center;

  .sponsors-page-contact-submit {
    background-color: #8eefa1;
    border: none;
    color: white;
    font-size: 1em;
    padding: 0.7em 1.5em;
    text-decoration: none;
    //margin: 2em 5em;
    border-radius: 2em;
    cursor: pointer;

    &.disable {
      cursor: unset;
      background-color: lightgrey;
    }
  }
}

.sponsors-page-dropdown select {
  color: #747489;
  border: none;
  border-bottom: thin solid rgb(202, 202, 202);
  //width: 20em;
  font-size: 1em;
  float: left;
}

.sponsors-page-dropdown select option {
  padding: 30px;
}

.sponsors-page-dropdown {
  h3 {
    margin: 0.5em 0;
    font-size: 1.25em;
    color: rgb(97, 97, 97);
    text-align: left;
  }

  margin-bottom: 2em;
  // margin: auto 5em;
}

@media only screen and (max-width: 700px) {

  .submission-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1em auto;
  }

  .submission-message{
    margin: 1em auto;
  }
}

@media only screen and(min-width: 476px){
  .submission-container {
    .sponsors-page-contact-submit {
      margin: 2em 5em;
    }
  }
}
