.right-side-board-image {
  margin-top: 0.1em;
  width: 80%;
  border-radius: 0.4em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.right-side-board-header {
  font-weight: bold;
  font-size: 0.9em;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
  text-align: Left;
  margin-left: 1em;
  margin-right: 1em;
  color: #464646;
}

.right-side-board-text {
  font-size: 0.7em;
  margin-top: 0.2em;
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
  color: #464646;
}

@media only screen and (max-width: 1040px) { 
  .right-side-board-header { 
    text-align: center;
  }
  .right-side-board {
    width: 50%;
  }
  .billboard_events_container {
    width: 100%;
    display: flex;
    text-align: center;
  }
  .right-side-board-image {
    margin-top: 0.1em;
    width: 20em;
    height: auto;
    border-radius: 0.4em;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .events {
    width: 50%;
    height: fit-content;
    img {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 700px) { 
  .right-side-board-header { 
    text-align: center;
    font-size: 1em;
  }
  .right-side-board {
    img {
      width: 90%;
    }
    p {
      font-size: 1em;
    }
  }
  .events {
    width: 95%;
    margin: auto;
  }
}