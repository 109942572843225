@import "../variables.scss";

.right-side-bar {
  width: 15em;
  background-color: #ffffff;
  border-radius: 0.25em;
  height: fit-content;
  margin-left: 1em;
  padding-bottom: 1.5em;
  min-height: $min-column-height;

  & > div {
    margin-bottom: 2em;
  }
}
@media only screen and (max-width: 1040px) {
  .right-side-bar {
    width: 95%;
    margin: 1em auto;
    min-height: auto;
  }
  .billboard_events_container {
    width: 100%;
    display: flex;
  }
}

@media only screen and (max-width: 700px) {
  .right-side-bar {
    width: 90%;
    margin: auto;
    font-size: 1em;
    padding: 0;
    min-height: auto;
  }
  img {
    max-height: 20em;
  }
  .billboard_events_container {
    display: block !important;
  }
}
