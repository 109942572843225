#order-popup-container {
  margin: 2em 0;

  ul {
    margin: auto;
    padding: 0;
  }

  li {
    img .canadian-dream-1 {
      width: 15em;
    }
  }

  .thumbs-wrapper {
    margin: 1em 0;
  }

  p {
    margin: 0;
    font-size: 1em;
  }

  .popup-title {
    font-weight: bold;
    font-size: 1em;
  }

  .popup-description {
    font-size: 0.9em;
    margin-bottom: 1em;
  }

  .popup-shipping-message {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1040px) {
  #order-popup-container {
    .popup-title {
      font-size: 1.4em;
      margin-bottom: 0.5em;
    }

    .popup-description {
      font-size: 1em;
    }

    .popup-pricing {
      font-size: 1.3em;
      margin-bottom: 0.5em;
    }
  }
}
