$primaryColor: #6200ee;
$secondaryColor: rgb(126, 201, 226);
$headerFontColor: #464646;

// Breakpoints
$mobile: 576px;
$tablet: 768px;
$desktop: 992px;

// Home page
$min-column-height: 57em; // Desktop only
