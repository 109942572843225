.footer_container {
  width: 100%;
  background-color: rgb(59, 59, 59);
  font-family: Poppins, sans-serif;

  a {
    text-decoration: none;
    color: white;
  }
}

.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
  background-color: rgb(59, 59, 59);
  padding: 1em 0;

  a {
    text-decoration: none;
    color: white;
  }

  h2 {
    color: white;
    font-size: 1.75em;
    text-align: center;
    font-weight: 400;
  }

  .email-link {
    text-decoration: underline;
  }

  .partner-logo {
    width: 30%;
  }
}

.partner_image {
  display: flex;
  justify-content: center;
  img {
    max-height: 8em;
    max-width: 15em;
    margin: auto;
  }
}

.image_row_one {
  width: 40em;
  margin: auto;
}

.image_row_two {
  width: 60em;
  margin: auto;
}

.grey_line {
  width: 80%;
  height: 1px;
  background-color: grey;
  margin-left: 9em;
}

.links_bottom_row {
  display: flex;
  width: 80%;
  margin-left: 9em;
  h1 {
    color: white;
  }
}
.links_container {
  display: flex;
  margin-left: auto;
  width: fit-content;
  align-items: center;
  h1 {
    color: white;
    margin: auto;
  }
}

.link_container {
  display: flex;
  margin-left: 1em;

  h4 {
    font-size: 0.9em;
    margin: auto 0.5em;
    color: white;
    font-weight: lighter;
  }
}

.LinkedInLogo {
  margin: auto -0.1em;
}

.horizontal_grey_line {
  height: 2em;
  width: 0.1em;
  background-color: rgb(99, 99, 99);
  margin: auto 0.5em;
}

.real_footer_container {
  background-color: rgb(85, 85, 85);
  width: 100%;
  margin: 0;
  padding-bottom: 1em;
}

.three_coloum_container {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.coloum_of_links {
  h4 {
    color: white;
    font-weight: lighter;
    font-size: 1em;
    margin: 1em 0 ;
  }
  span {
    color: rgb(153, 153, 153);
  }
  width: 33%;
  text-align: left;
}

.yellow_line_thin {
  background-color: yellow;
  width: 100%;
  height: 0.25em;
}

#boxia-text {
  color: white;
}

@media only screen and (max-width: 1240px) {
  .grey_line {
    margin: auto;
  }
  .links_bottom_row {
    margin: auto;
  }
}

@media only screen and (max-width: 564px) {
  .footer {
    width: 100%;
    h2 {
      font-size: 1.5em;
    }
  }
  $width_of_coloum_container: 95%;
  .three_coloum_container {
    width: $width_of_coloum_container;
    justify-content: space-between;
    margin: auto;
  }
  .coloum_of_links {
    margin: 0 0.5em;
    margin-left: 1.1em;
    width: $width_of_coloum_container / 3;
    display: none;
  }
  .links_bottom_row {
    padding-top: 0.5em;

    #boxia-text{
      margin: 0;
    }
  }
  .grey_line {
    margin: auto;
  }
  .link_container {
    margin-top: 0.4em;
    img {
      margin: auto;
    }
  }
}
