$width_of_content: 70em * 0.9;

.bio_page_com {
    display: flex;
    img {
        width: $width_of_content * 0.2;
        height: $width_of_content * 0.2;
        border-radius: 50%;
        margin-left: $width_of_content * 0.05;
    }
}

.bio_page_com_text {
    width: $width_of_content * 0.75;
    margin-left: 3em;
    p {
        font-size: 1em;
    }
}

.spacer_div_bio_page {
    height: 3em;
}

@media only screen and (max-width: 564px) { 
    .bio_page_com {
        width: 100%;
        display: block;
        img {
            width: 50%;
            height: 50%;
            margin: auto;
            display: block;
        }
    }
    .bio_page_com_text {
        width: 90%;
        margin: auto;
        text-align: center;
    }
}