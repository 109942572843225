.services {
  background-color: #dfdddb;
  width: 75em;
  margin: auto;
  padding-bottom: 2em;

  .page-header-container {
    margin-bottom: 1em;
  }
}

.services-mobile-img-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
}

.services-img-mobile {
  width: calc((100vw - 1rem) / 2);
  height: auto;
  align-self: center;
  max-height: 1000rem;
}

.services-img-desktop {
  width: 100%;
  height: auto;
}

@media only screen and (min-width:1178px) { 
  .services {
    width: 75em;
  }
}


.services-page-square-image {
  width: 20em;
  height: 20em;
  object-fit: cover;
  border-radius: 0.25em;
  // background-color: black;
}

.services-page-square {
  position: relative;
  margin: 1em;
  height: 20em;
}

.services-page-square-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 20em;
  height: 6em;
  bottom: 0;
  color: white;
  position: absolute;
  border-radius: 0 0 0.25em 0.25em;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-page-square-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  background-color: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 70%
  );
}

.services-page-square-overlay-text {
  padding: 0.5em;
  margin: 0;
  font-weight: bold;
  opacity: 1;
  font-size: 1.3em;
  z-index: 2;
  text-align: center;
}

.services-page-mobile{
  background-color: #dfdddb;
  margin-left:0.5em;
  margin-right:0.5em;
}


