.right-side-donation {
  margin-top: 1em;
  width: 95%;
  margin-left: 0.5em;
  margin-right: 0.5em;

  .dbox-donation-button {
    margin-top: 0.2em;
    background: #2d81c5 url(https://d1iczxrky3cnb2.cloudfront.net/red_logo.png)
      no-repeat 18px center;
    color: #fff;
    text-decoration: none;
    font-family: Verdana, sans-serif;
    display: inline-block;
    font-size: 16px;
    padding: 13px 17px 13px 56px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    box-shadow: 0 1px 0 0 #1f5a89;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 1040px) { 
  .right-side-donation {
    margin-top: 2em;
    padding-top: 1em;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (max-width: 700px) { 
  .right-side-donation {
    margin-top: 2em;
    padding-top: 1em;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
