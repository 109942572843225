#content {
  display: flex !important;
  flex-direction: row;
  max-width: 75em;
  text-align: center;
  min-height: 80vh;
  margin: auto;
  justify-content: center;

  .center-column{
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1040px) { 
  #content {
    display: block !important;
  }
}

