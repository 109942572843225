$width_of_content: 75em;
.contact_page {
  min-height: 75vh;
  .contact_page_content {
    width: $width_of_content;
    margin: auto;
  }
}

.title {
  margin: 0 auto;
  width: $width_of_content;
  height: 15em;
  background-image: url("../img/contact-us-edit.jpg");
  background-size: 100% 100%;
  position: relative;
}

.dark_filter {
  width: inherit;
  height: inherit;
  background-color: rgba(0, 0, 0, 0.35);
  h1 {
    position: absolute;
    bottom: 0.5em;
    left: 0.5em;
    margin: 0;
    color: white;
  }
}

.contact_form_section {
  background-color: rgba(255, 255, 255, 0.5);
  display: block;
  width: $width_of_content;
  margin: auto;
  padding: 3em 0;

  .coming-soon-emailing-text {
    text-align: center;

    & > a {
      color: black;
      text-decoration: underline;
    }
  }
}

.contact_form {
  background-color: white;
  border-radius: 2em;
  width: $width_of_content * 0.9;
  margin: auto;
  padding: 4em 0;
}

.basic_information {
  display: flex;
  justify-content: center;
  width: ($width_of_content * 0.9) * 0.9;
  margin: auto;
}

.basic_input {
  h3 {
    margin: 0.5em 0;
    font-size: 1.25em;
    color: rgb(97, 97, 97);
  }
  input {
    border: none;
    border-bottom: thin solid rgb(202, 202, 202);
    width: ($width_of_content * 0.9) / 3.5;
    margin: auto;
  }
  margin-bottom: 1em;
}

.first_input {
  margin-right: auto;
}

.middle_input {
  margin: 0 auto;
}

.last_input {
  margin-left: auto;
}

.message {
  width: ($width_of_content * 0.9) * 0.9;
  margin: auto;
  h3 {
    margin: 0.5em 0;
    font-size: 1.25em;
    color: rgb(97, 97, 97);
  }
  textarea {
    border: none;
    border-bottom: thin solid rgb(202, 202, 202);
    width: 100%;
    height: 20vh;
    margin: auto;
    resize: none;
  }
  ::placeholder {
    font-family: Arial, Helvetica, sans-serif;
  }
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 1em ($width_of_content * 0.9) * 0.05;
  align-items: center;
  overflow: hidden;
}

.submit_text {
  border-radius: 5em;
  background-color: rgb(79, 192, 79);
  font-size: 1.25em;
  color: white;
  line-height: 2em;
  text-align: center;
  padding: 0 2em;
  cursor: pointer;

  &:hover {
    background-color: rgb(72, 175, 72);
  }

  &.disable {
    cursor: unset;
    background-color: lightgrey;
  }
}

@media only screen and (max-width: 1040px) {
  .contact_page {
    width: 95%;
    margin: auto;
    min-height: 50vh;
    .contact_page_content {
      width: 100%;
      margin: auto;
    }
  }
  .title {
    margin: auto;
    width: 100%;
  }
  .contact_form {
    width: 90%;
    margin: auto;
    padding: 1em 0;
  }
  .contact_form_section {
    width: 100%;
  }
  .basic_information {
    justify-content: space-between;
    width: 95%;
    margin: auto;
  }
  .basic_input {
    width: 30%;
    input {
      width: 100%;
    }
  }
  .message {
    width: 95%;
    textarea {
      max-height: 20em;
    }
  }
}

@media only screen and (max-width: 700px) {
  .contact_form_section {
    padding: 1em 0;
  }
  .contact_page {
    min-height: 75vh;
    .contact_page_content {
      width: 100%;
      margin: auto;
    }
  }
  .title {
    margin: auto auto;
    width: 90%;
    height: 6em;
  }
  .dark_filter {
    width: 100%;
    height: inherit;
  }
  .contact_form {
    width: 90%;
    margin: auto;
    padding: 1em 0;
  }
  .middle_input, .last_input {
    margin: 0;
  }
  .contact_form_section {
    width: 90%;
  }
  .basic_information {
    display: block;
    margin: auto;
    width: 90%;
  }
  .message {
    width: 90%;
    textarea {
      border: none;
      border-bottom: thin solid rgb(202, 202, 202);
      width: 100%;
      height: 5em;
      margin: auto;
      resize: none;
    }
    ::placeholder {
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  .submit{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1em auto;
  }

  .submission-message{
    margin: 1em auto;
  }

  .basic_input {
    width: 100%;
    input {
      width: 100%;
    }
  }
}
