.blog_post {
  width: auto;
  margin: 0 1em;
  text-align: left;

  img {
    max-width: 100% !important;
    height: auto;
  }
  a {
    color: navy;
  }

  .collapsable-block {
    font-size: 0.75em;
    text-align: left;
    margin: 0.5em 0;
  }

  *{
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1040px) { 
  .blog_post {
    display: flex;
    flex-direction: column;
    width: 95%;
    .collapsable-block {
      font-size: 0.75em;
      text-align: left;
      margin: 0.5em 0;
      width: 100%;
    }
  }
  .mobile_blog_post_view {
    width: 33.33%;
  }
  .blog_sponsors {
    display: flex;
    justify-content: space-evenly;
    width: 66.66%;
    margin: auto;
    img {
      height: 100%;
      margin: auto;
      display: flex;
    }
  }
}

@media only screen and (max-width: 700px) { 
  .blog_post {
    display: block;
    padding-bottom: 1em;
    margin-left: 0;

    .collapsable-block {
      width: 100%;
      font-size: 1em !important;
    }

    p{
      margin-left: 20px !important;
    }
  }
  .mobile_blog_post_view {
    width: 100%;
    img {
      max-height: 20em;
    }
  }
  .blog_content_sponsors_images {
    img {
      display: block;
      margin:0em auto;
    }
  }
  .blog_sponsors {
    display: block;
    width: 100%;
    img {
      width: 9em;
      max-height: 20em;
      margin: auto;
    }
  }
}
