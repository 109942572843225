.ebook-complete-dialog{
  .content-container{
    display: flex;
    flex-direction: column;
  }

  p{
    font-size: 1.1em;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 0;
  }

  .logo-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .et-logo,
    .boxia-logo{
      margin-top: 0.5em;
    }

    .et-logo{
      width: 32%;
      height: auto;
    }
  
    .boxia-logo{
      width: auto;
      height: 4em;
    }
  }

  button{
    & > a {
      color: inherit; /* blue colors for links too */
      text-decoration: inherit; /* no underline */
    }
  }
}