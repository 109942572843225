$width_of_content: 75em;
.bio_page {
  padding: 1em 0;
}

.bio_page_content {
  width: $width_of_content;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.6);
  padding-bottom: 1em;
}

.bio_page_title {
  width: $width_of_content;
  height: 15em;
  background-image: url("../../img/biographies-edit.jpg");
  background-position: center;
  background-size: cover;
  h1 {
    position: absolute;
    bottom: 0.25em;
    left: 0.5em;
    margin: 0;
    color: white;
    font-weight: 600;
  }
  position: relative;
}

@media only screen and (max-width: 564px) { 
  .bio_page {
    width: 90%;
  }
}
@media only screen and (max-width: 1040px) { 
  .bio_page {
    width: 95%;
    margin: auto;
  }
  .bio_page_content {
    width: 100%;
  }
  .bio_page_title {
   width: 100%;
   height: 6em;
    margin: auto;
  }
  .bio_page_title { 
    width: 100%;
  }
}