$width_of_content: 70em;

.content {
    margin: 2em 0;
    display: block !important;
    width: $width_of_content * 0.4;
    p {
        text-align: left;
        font-size: 0.9em;
        width: 90%;
    }
}

.top_content {
    display: flex;
    img {
        width: $width_of_content * 0.15;
        height: $width_of_content * 0.15;
        border-radius: 50%;
    }
}

.title_name {
    width: $width_of_content * 0.2;
    margin: auto 2em;
    h2, h3 {
        text-align: left;
        margin: 0.1em;
    }
    h3 {
        font-size: 0.9em;
    }
}

.see_more {
    text-align: left;
    color: black;
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width: 564px) { 
    .content {
        display: block !important;
        width: 100%;
    }
    .top_content {
        display: flex;
        img {
            width: 25vw;
            height: 25vw;
        }
    }
    .see_more {
        text-align: left;
        color: black;
        cursor: pointer;
        text-decoration: underline;
        margin: 0;
    }
    .content {
        p {
            margin: 0;
        }
    }
}
@media only screen and (max-width: 1040px) { 
    .content {
        p {
            font-size: 1.5em;
        }
    }
    .title_name {
        width: 40%;
        h2, h3 {
            font-size: 1.5em;
        }
        h3 {
            font-size: 1em;
        }
    }
    .see_more {
        font-size: 1.5em;
    }
}