.comic-of-the-day {
  margin-top: 1em;
  flex-grow: 1;
  background-color: rgb(255, 255, 255);
  border-radius: 0.25em;
  height: fit-content;
}

.comic-of-the-day-image {
  margin-top: 0.75em;
  width: auto;
  border-radius: 0.25em;
  margin-left: 1em;
  margin-right: 1em;
  max-height: 30em;

  & > img {
    max-width: 100%;
    max-height: 25em;
  }
}

.switch-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 3em;
  margin-top: 1em;
}

.button {
  color: black;
  margin-bottom: 1em;
}

.comic-of-the-day-signature {
  margin: 0;
  font-size: 0.8em;
}

// Facebook plugin styling
.comic-comments-facebook-like {
  margin-left: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: left;
  width: 10em;
  margin: auto;
}

@media only screen and (max-width: 1040px) {
  .comic-of-the-day {
    width: 95%;
    margin: auto;
    margin-top: 1em;
    padding: 1em 0;
  }
  .comic-of-the-day-image {
    max-height: fit-content;
    & > img {
      width: 60%;
      max-height: fit-content;
    }
  }
  .switch-buttons {
    .button {
      font-size: 1.5em;
    }
  }
  .comic-comments-facebook-like {
    margin: 1em 0;
    margin-left: 1em;
  }
}

@media only screen and (max-width: 700px) {
  .comic-of-the-day {
    flex-grow: 1;
    width: 90%;
    background-color: rgb(255, 255, 255);
    border-radius: 0.25em;
    height: fit-content;
    margin: auto;
    padding: 1em 0;
  }
  .comic-of-the-day-signature {
    text-align: center;
    font-size: 1em;
  }
  .comic-comments-facebook-like {
    margin: auto 1em;
    max-width: 100%;
    iframe {
      max-width: 100%;
    }
  }
  .facebook-force-to-stay-certain-width {
    max-width: 100%;
  }
  .fb-like,
  .fb_iframe_widget,
  .fb_iframe_widget_fluid {
    max-width: 100%;
    span {
      max-width: 200%;
    }
    span iframe {
      max-width: 100%;
    }
  }
}
